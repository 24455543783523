import 'keen-slider/keen-slider.min.css';
import {AnchorLink} from 'components/AnchorLink';
import Bold from 'components/Bold';
import {Button, ButtonLink} from 'components/Button';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Flex} from 'components/Flex';
import {HubspotForm} from 'components/HubspotForm';
import {StyledDiv, StyledImg, StyledP} from 'components/StyledSx';
import {Field, Form as _Form, Formik, useFormikContext} from 'formik';
import applePay from 'images/applepay-logo-small.svg';
import baloon from 'images/baloon.svg';
import bizum from 'images/bizum_payment_gateway.svg';
import airplane from 'images/blog-plane.svg';
import business from 'images/business.svg';
import ecommerce from 'images/ecommerce.svg';
import envelope from 'images/envelope.svg';
import freelancer_pos from 'images/freelancer_pos.svg';
import googlePay from 'images/googlepay-logo-small.svg';
import hairdessers from 'images/hairdessers.svg';
import hand_dollar from 'images/hand_dollar.svg';
import hand_wallet from 'images/hand_wallet.svg';
import heart_baloon from 'images/heartballoon.svg';
import mango from 'images/mango-logo.png';
import marriot from 'images/marriott_Logo.png';
import mediamarkt from 'images/mediamarkt_logo.svg';
import mobileQr from 'images/mobile_qr.svg';
import mobileRtp from 'images/mobile_rtp.svg';
import moneiPayWhiteLogo from 'images/MONEI Pay logo blanco.svg';
import moneiPayLogo from 'images/MONEI Pay logo.svg';
import moneiPayFooterBg from 'images/monei_pay_footer_bg.svg';
import moneiPayMainBg from 'images/monei_pay_main_bg.svg';
import person_icon from 'images/person_icon.svg';
import phone from 'images/phone.svg';
import pos_card from 'images/pos_card.svg';
import repsol from 'images/repsol_logo.png';
import restaurant from 'images/restaurant.svg';
import rocket from 'images/rocket.svg';
import scalpers from 'images/scalpers_logo.svg';
import taxi_pos from 'images/taxi_pos.svg';
import team from 'images/team.svg';
import corner from 'images/video_left_bottom_corner.svg';
import wallet_dollar from 'images/wallet_dollar.svg';
import {useKeenSlider} from 'keen-slider/react';
import {parsePhoneNumber} from 'libphonenumber-js';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import * as Yup from 'yup';
import {ScreenSizes} from '../../../types/responsive';

const Content = styled(StyledDiv)<{fullWidth?: boolean}>`
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  padding: 0 82px;
  position: relative;
  z-index: 11;
  @media (max-width: ${ScreenSizes.lg}) {
    padding: 0 42px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    padding: 0 21px;
  }
`;

const Logo = styled.img`
  height: 32px;
  margin-bottom: 0;
  @media (max-width: ${ScreenSizes.sm}) {
    height: 24px;
  }
  @media (max-width: ${ScreenSizes.xs}) {
    height: 16px;
  }
`;

const Section = styled(StyledDiv)`
  width: 480px;
  position: relative;
  h1 {
    font-weight: 300;
    font-size: 50px;
    line-height: 120%;
    color: #474747;
    position: relative;
    padding-bottom: 11px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      width: 100%;
      background: linear-gradient(225deg, #a1aee9 0.11%, #05ceac 100.11%);
    }
    @media (max-width: ${ScreenSizes.xs}) {
      font-size: 30px;
    }
  }
  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 120%;
    position: relative;
    color: #474747;
    padding-bottom: 11px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      width: 100%;
      background: linear-gradient(225deg, #a1aee9 0.11%, #05ceac 100.11%);
    }
    @media (max-width: ${ScreenSizes.xs}) {
      font-size: 20px;
    }
  }
  p {
    font-weight: 300;
    font-size: 24px;
    line-height: 125%;
    color: #474747;
    @media (max-width: ${ScreenSizes.xs}) {
      font-size: 16px;
    }
  }
`;

const CompatibleWith: React.FC = () => {
  return (
    <Flex direction="column">
      <StyledP
        sx={{
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: '300',
          fontSize: '18px',
          lineHeight: '125%',
          color: '#474747',
          marginTop: '16px'
        }}>
        Compatible con:
      </StyledP>
      <Flex>
        <Logo src={applePay} />
        <Logo src={googlePay} style={{margin: '0 24px'}} />
        <Logo src={bizum} />
      </Flex>
    </Flex>
  );
};

const CTAContainer = styled.div`
  flex: 1;
  max-width: 100%;
  @media (max-width: ${ScreenSizes.sm}) {
    margin: auto;
  }
`;

const CTAContent = styled.div`
  background: rgba(171, 181, 229, 1);
  border-radius: 8px;
  color: #ffffff;
  position: relative;
  padding: 21px 26px;
  width: 485px;
  max-width: 100%;
  margin: auto;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 35px;
    color: #ffffff;
    @media (max-width: ${ScreenSizes.xs}) {
      margin-bottom: 8px;
    }
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    @media (max-width: ${ScreenSizes.xs}) {
      margin-bottom: 8px;
    }
  }
`;
const PrivacyText = styled.span`
  font-style: normal;
  font-weight: 300;
  color: #ffffff;
  font-size: 12px;
  line-height: 125%;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const Form = styled(_Form)`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 0;

  button[disabled] {
    box-shadow: none;
    opacity: 0.7;
    pointer-events: none;
  }
`;

const FormFieldWrapper = styled.div`
  position: relative;
  img {
    position: absolute;
    left: 16px;
    top: 16px;
  }
  .error {
    position: absolute;
    color: #ffffff;
    font-size: 0.9rem;
    font-style: italic;
    transition: all 0.3s ease-in-out;
  }
`;

const FormField = styled(Field)`
  flex: 1;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 11px 9px 7px 42px;
  outline: transparent;
  caret-color: #ffffff;
  color: #ffffff;

  &::placeholder {
    color: #ffffff;
  }
  &:active {
    border: 1px solid #ffffff;
  }
`;

type FormValues = {
  name?: string;
  lastname?: string;
  email?: string;
  phone?: string;
};

const CTASectionForm: React.FC = () => {
  const {isSubmitting, dirty, errors, touched, submitCount} = useFormikContext<FormValues>();

  return (
    <Form>
      <StyledDiv
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: {all: 'center', xs: 'unset'},
          flexDirection: {all: 'row', xs: 'column'}
        }}>
        <FormFieldWrapper>
          <img src={person_icon} width={16} height={16} alt="Contact MONEI" />
          <FormField type="text" name="name" placeholder="Nombre" />
          {errors.name && (touched.name || submitCount > 0) && (
            <div className="error">{errors.name}</div>
          )}
        </FormFieldWrapper>

        <FormFieldWrapper>
          <img src={person_icon} width={16} height={16} alt="Contact MONEI" />
          <FormField type="text" name="lastname" placeholder="Apellidos" />
        </FormFieldWrapper>
      </StyledDiv>
      <FormFieldWrapper>
        <img src={envelope} width={20} height={14} alt="Contact MONEI" />
        <FormField type="email" name="email" placeholder="Correo electrónico" />
        {errors.email && (touched.email || submitCount > 0) && (
          <div className="error">{errors.email}</div>
        )}
      </FormFieldWrapper>

      <FormFieldWrapper>
        <img src={phone} width={18} height={18} alt="Contact MONEI" />
        <FormField type="text" name="phone" placeholder="Número de teléfono" />
        {errors.phone && (touched.phone || submitCount > 0) && (
          <div className="error">{errors.phone}</div>
        )}
      </FormFieldWrapper>

      <FormFieldWrapper>
        <img src={business} width={18} height={18} alt="Contact MONEI" />
        <FormField type="text" name="business" placeholder="Escriba el nombre de su empresa" />
      </FormFieldWrapper>

      <Button
        variant="dark"
        type="submit"
        disabled={isSubmitting || !dirty}
        style={{width: 'fit-content', margin: 'auto'}}>
        Continuar
      </Button>
    </Form>
  );
};
const CTASection: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Escribe el nombre'),
    email: Yup.string().email('Email no válido').required('Escribe el Email'),
    phone: Yup.string()
      .required('Escribe el teléfono')
      .test('phone', 'Teléfono no válido', (value) => {
        try {
          if (!value) return false;
          const phoneNumber = parsePhoneNumber(value, 'es');
          return phoneNumber.isValid();
        } catch (e) {
          return false;
        }
      })
  });

  const onSubmit = async (data: FormValues, {resetForm}) => {
    const formData = new FormData();
    formData.append('u', '3');
    formData.append('f', '3');
    formData.append('s', '');
    formData.append('c', '0');
    formData.append('m', '0');
    formData.append('act', 'sub');
    formData.append('v', '2');
    formData.append('or', '52eb2eccc44ec00142df31264088a92c');

    formData.append('name', data.name);
    formData.append('lastname', data.lastname);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('field[1]', data.business);

    fetch('https://monei17349.activehosted.com/proc.php', {
      method: 'POST',
      body: formData,
      mode: 'no-cors'
    })
      .then(() => {
        setIsSubmitted(true);
        return resetForm();
      })
      .catch(() => {
        setIsError(true);
      });
  };

  return (
    <CTAContainer id="contact-form">
      <CTAContent>
        <StyledImg src={baloon} sx={{position: 'absolute', right: '-42px', top: '-90px'}} />
        <StyledDiv sx={{paddingRight: '90px'}}>
          <h2>Olvídate de los problemas de red, acepta los pagos desde tu teléfono o tableta.</h2>
          <p style={{marginBottom: 0}}>
            Únete a <Bold>MONEI Pay</Bold> sin compromiso para probar integraciones y pagos.
          </p>
        </StyledDiv>
        <br />
        <Formik
          initialValues={{} as FormValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnBlur
          enableReinitialize>
          <CTASectionForm />
        </Formik>
        {isError ? (
          <p style={{margin: '12px 0 0'}}>
            Ha ocurrido un error al enviar el formulario, inténtalo más tarde.
          </p>
        ) : null}
        {isSubmitted ? (
          <p style={{margin: '12px 0 0'}}>
            Gracias por tu interés, nos pondremos en contacto contigo muy pronto.
          </p>
        ) : null}
        <br />
        <PrivacyText>
          Al completar los datos aceptas el{' '}
          <AnchorLink href="https://monei.com/es/privacy-policy/">Aviso de privacidad</AnchorLink>.
        </PrivacyText>
      </CTAContent>
    </CTAContainer>
  );
};

const MoneiPayCard = styled.div`
  background-color: white;
  position: relative;
  flex: 1;
  overflow: visible !important;
  border-radius: 8px;
  padding: 80px 15px 40px;
  border: 0.5px solid #e6e6e6;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 64px 64px rgba(61, 88, 128, 0.05), 0 32px 32px rgba(61, 88, 128, 0.05),
    0 16px 16px rgba(61, 88, 128, 0.05), 0 8px 8px rgba(61, 88, 128, 0.05),
    0 4px 4px rgba(61, 88, 128, 0.05), 0 2px 2px rgba(61, 88, 128, 0.05);
  @media (max-width: ${ScreenSizes.xs}) {
    padding: 80px 10px 40px;
  }
  .mobileImg {
    position: absolute;
    width: 205px;
    top: -50px;
    left: calc(50% - 102.5px);
    @media (max-width: ${ScreenSizes.xs}) {
      width: 160px;
      left: calc(50% - 80px);
    }
  }
  h2 {
    margin: auto;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 125%;
    text-align: center;
    color: #474747;
    max-width: 100%;
    @media (max-width: ${ScreenSizes.xs}) {
      font-size: 22px;
    }
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    color: #474747;
    margin: 0 auto 22px;
    max-width: 100%;
    @media (max-width: ${ScreenSizes.xs}) {
      font-size: 15px;
    }
`;

const BackgroundContainer = styled(StyledDiv)`
  display: flex;
  gap: 40px;
  top: -20px;
  justify-content: center;
  align-items: center;
  transform: rotate(-10deg);
  position: relative;
  padding: 60px 0;
  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 150vw;
    height: 100%;
    top: 0;
    right: 0;
    left: -27vw;
    bottom: 0;
    background: linear-gradient(225deg, #bfa1e9 0.11%, #05ceac 100.11%);
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding: 20px 40px;
    top: 20px;
    flex-wrap: wrap;
    gap: 10px 30px;
  }
  @media (max-width: ${ScreenSizes.xs}) {
    padding: 30px 5px;
    flex-wrap: wrap;
    gap: 0 37px;
  }
`;
const FlatCard = styled(StyledDiv)`
  position: relative;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1 0 max-content;
  border-radius: 5px;
  padding: 15px;
  color: white;
  transform: rotate(9deg);
  @media (max-width: ${ScreenSizes.xs}) {
    max-width: 110px;
  }

  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 125%;
    @media (max-width: ${ScreenSizes.xs}) {
      font-size: 16px;
    }
  }
`;

const CardsContainer = styled(StyledDiv)`
  position: relative;
`;

const Card = styled(StyledDiv)`
  padding: 25px 15px 20px;
  text-align: center;
  color: #474747;
  line-height: 125%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 5px 20px 30px rgba(117, 127, 177, 0.25);

  .imgWrapper {
    display: flex;
    align-items: center;
    height: 79px;
    img {
      margin-bottom: 0;
    }
  }

  h4 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 8px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    margin: 0;
  }
`;

const clientsList = [
  {
    src: mediamarkt,
    alt: 'Mediamarkt payment gateway',
    width: 200,
    sx: {width: {all: '200px', md: '140px', xs: '73px'}}
  },
  {
    src: repsol,
    alt: 'Repsol payment gateway',
    width: 200,
    sx: {width: {all: '200px', md: '140px', xs: '69px'}}
  },
  {
    src: mango,
    alt: 'Mango payment gateway',
    width: 200,
    sx: {width: {all: '200px', md: '140px', xs: '56px'}}
  },
  {
    src: marriot,
    alt: 'Marriot payment gateway',
    width: 200,
    sx: {width: {all: '200px', md: '140px', xs: '63px'}}
  },
  {
    src: scalpers,
    alt: 'Scalpers payment gateway',
    width: 200,
    sx: {width: {all: '200px', md: '140px', xs: '66px'}}
  }
];

const StyledClientLogo = styled.div`
  opacity: 0.7;
  transition: 250ms opacity;
  display: flex;
  align-items: center;

  :hover {
    opacity: 1;
  }

  img {
    margin: 0;
  }

  @media (max-width: 768px) {
    margin: 0;
    max-width: 100%;
  }
`;

const Slider = styled(StyledDiv)``;

const Dots = styled.div`
  display: flex;
  padding: 30px 0 0;
  justify-content: center;
  @media (min-width: ${ScreenSizes.xs}) {
    display: none;
  }

  .dot {
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    border: 1px solid #cea8f6;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    background: #cea8f6;
  }
`;

const ContactSalesSquareButton = styled(ContactSalesButton)`
  min-width: unset;
  width: 235px;
  height: 177px;
  background: linear-gradient(225deg, #a1aee9 0.11%, #05ceac 100.11%);
  box-shadow: 1px 2px 3px rgba(117, 127, 177, 0.25);
  border-radius: 8px;
  padding: 0;
  @media (max-width: ${ScreenSizes.lg}) {
    width: 195px;
    height: 147px;
  }
  > span {
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin: 0;
      width: 68px;
      height: 48px;
      @media (max-width: ${ScreenSizes.md}) {
        width: 48px;
        height: 34px;
      }
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 136%;
      color: #ffffff;
      margin: 0;
      @media (max-width: ${ScreenSizes.md}) {
        font-size: 12px;
        line-height: 133%;
      }
    }
  }
`;

const SquareButtonLink = styled(ButtonLink)`
  min-width: unset;
  width: 235px;
  height: 177px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(225deg, #a1aee9 0.11%, #05ceac 100.11%);
  box-shadow: 1px 2px 3px rgba(117, 127, 177, 0.25);
  border-radius: 8px;
  padding: 0;
  @media (max-width: ${ScreenSizes.lg}) {
    width: 195px;
    height: 147px;
  }
  img {
    margin: 0;
    width: 56px;
    height: 56px;
    @media (max-width: ${ScreenSizes.md}) {
      width: 34px;
      height: 34px;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 136%;
    color: #ffffff;
    margin: 0;
    @media (max-width: ${ScreenSizes.md}) {
      font-size: 12px;
      line-height: 133%;
    }
  }
`;

const MoreInfoButton: React.FC = () => {
  const goToForm = () => {
    document.getElementById('contact-form')?.scrollIntoView({behavior: 'smooth'});
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <Button variant="light" style={{marginRight: 15}} onClick={handleScrollToTop}>
      Solicitar información
    </Button>
  );
};

const SemEsp: React.FC = () => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: 'snap',
    slides: {perView: 'auto', spacing: 0},
    slideChanged: (ev) => {
      setCurrentSlide(ev.track.details.abs);
    },
    breakpoints: {
      ['(max-width: ' + ScreenSizes.sm + ')']: {
        slides: {origin: 'center', perView: 'auto', spacing: 0}
      }
    }
  });
  const [currentSlide2, setCurrentSlide2] = React.useState(0);

  const [sliderRef2, instanceRef2] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: 'snap',
    slides: {perView: 'auto', spacing: 0},
    slideChanged: (ev) => {
      setCurrentSlide2(ev.track.details.abs);
    },
    breakpoints: {
      ['(max-width: ' + ScreenSizes.sm + ')']: {
        slides: {perView: 3.5, spacing: 0}
      },
      ['(max-width: ' + ScreenSizes.xs + ')']: {
        slides: {perView: 1.7, spacing: 20}
      }
    }
  });

  useEffect(() => {
    if (!instanceRef.current) return;
    instanceRef.current.update();
  }, [instanceRef]);

  useEffect(() => {
    if (!instanceRef2.current) return;
    instanceRef2.current.update();
  }, [instanceRef2]);

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>MONEI Pay</title>
        <meta name="description" content="La mejor alternativa al datáfono está en tu móvil" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <StyledDiv sx={{position: 'relative', paddingBottom: '100px', fontFamily: 'Montserrat'}}>
        <Content sx={{marginTop: '66px'}}>
          <StyledDiv
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              gap: '100px',
              flexDirection: {all: 'row', sm: 'column'}
            }}>
            <StyledDiv sx={{marginBottom: {sm: '30px'}}}>
              <StyledImg src={moneiPayLogo} sx={{width: '300px', marginBottom: '54px'}} />
              <Section sx={{width: {all: '514px', sm: '100%'}}}>
                <h1 style={{marginBottom: '29px'}}>
                  La mejor alternativa al datáfono está en tu móvil
                </h1>
                <p style={{marginBottom: '36px'}}>
                  Pásate a <Bold>MONEI Pay</Bold>, la app TPV gratis. Cobra a los clientes desde
                  cualquier dispositivo móvil mediante códigos QR digitales o solicitudes de pago.
                </p>
                <CompatibleWith />
              </Section>
            </StyledDiv>
            <HubspotForm formId="1e6951bf-0cc8-4439-922b-f33a2ee988f5" minHeight={338} />
          </StyledDiv>
        </Content>
        <StyledImg
          src={moneiPayMainBg}
          sx={{
            position: 'absolute',
            marginBottom: '0',
            right: '0',
            top: {all: '-66px', lg: '10px'},
            width: {all: '770px', lg: '48%'},
            display: {all: 'block', md: 'none'}
          }}
        />

        <Content
          sx={{marginTop: {all: '172px', md: '85px'}, textAlign: 'center', position: 'relative'}}>
          <Section
            sx={{
              width: '100%',
              padding: {all: '0 79px', sm: '0 30px', xs: '0 18px'},
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
            <h2 style={{margin: '0 auto 90px'}}>
              ¿Cómo funciona <Bold>MONEI Pay</Bold>?
            </h2>
            <Slider
              ref={sliderRef}
              className="keen-slider"
              sx={{overflow: 'unset', gap: {all: '48px', xs: '20px'}}}>
              <MoneiPayCard className="keen-slider__slide">
                <StyledImg className="mobileImg" src={mobileQr} alt="monei pay qr" />
                <StyledDiv sx={{position: 'relative', padding: '0 15px', width: '100%'}}>
                  <h2 style={{marginBottom: '40px', width: '248px'}}>
                    Acepta pagos QR con <Bold>MONEI Pay</Bold>
                  </h2>
                  <p style={{width: '354px'}}>
                    1. Abre la app <Bold>MONEI Pay</Bold> en tu dispositivo móvil e introduce el
                    importe del pago.
                  </p>
                  <p style={{width: '225px'}}>
                    2. Aparecerá un código de pago <Bold>QR</Bold>
                  </p>
                  <p style={{width: '404px'}}>
                    3. Y listo, el cliente podrá <Bold>usar su teléfono</Bold> para escanear el
                    código QR, elegir su método de pago preferido y completar la compra.
                  </p>
                </StyledDiv>
                <MoreInfoButton />
              </MoneiPayCard>
              <MoneiPayCard className="keen-slider__slide">
                <StyledImg className="mobileImg" src={mobileRtp} alt="MONEI Pay RTP" />
                <StyledDiv sx={{position: 'relative', padding: '0 15px', width: '100%'}}>
                  <h2 style={{width: '298px', marginBottom: '10px'}}>
                    Envía solicitudes de pago con <Bold>MONEI Pay</Bold>
                  </h2>
                  <StyledImg src={bizum} alt="MONEI Bizum" sx={{width: '106px', margin: '0'}} />
                  <p style={{width: '322px'}}>
                    1. Introduce el importe de pago en la app <Bold>MONEI Pay</Bold>
                  </p>
                  <p style={{width: '363px'}}>
                    2. Haz click en el botón <Bold>«Enviar solicitud de pago»</Bold> e introduce el
                    número de teléfono móvil de tu cliente.
                  </p>
                  <p style={{width: '404px'}}>
                    3. Si su número de teléfono está registrado en <Bold>Bizum</Bold>, el cliente
                    recibirá una notificación automática para confirmar el pago directamente en la
                    aplicación bancaria; de lo contrario, recibirá un enlace para pagar por{' '}
                    <Bold>SMS</Bold>
                  </p>
                </StyledDiv>
                <MoreInfoButton />
              </MoneiPayCard>
            </Slider>
            <Dots>
              {[0, 1].map((idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(idx);
                      setCurrentSlide(idx);
                    }}
                    className={'dot' + (currentSlide === idx ? ' active' : '')}
                  />
                );
              })}
            </Dots>
          </Section>
        </Content>
        <Content sx={{marginTop: {all: '100px', md: '85px'}}}>
          <Section sx={{width: {xs: '241px'}}}>
            <h2>
              Algunas de sus <Bold>ventajas</Bold>
            </h2>
            <p>
              A diferencia de los sistemas TPV tradicionales, con <Bold>MONEI Pay</Bold> puedes:
            </p>
          </Section>
        </Content>
        <BackgroundContainer>
          <StyledImg
            src={airplane}
            alt="MONEI Pay App"
            sx={{
              width: {all: '330px', sm: '244px'},
              position: 'absolute',
              right: '-25px',
              top: {all: '-130px', sm: 'unset'},
              bottom: {sm: '-100px'},
              height: '214px',
              transform: 'rotate(9deg)',
              marginBottom: '0'
            }}
          />
          <FlatCard sx={{left: {sm: '15px', xs: '20px'}}}>
            <StyledImg src={hand_dollar} alt="" sx={{width: {all: '95px', xs: '80px'}}} />
            <p>Ahorrar en hardware y comisiones de transacción</p>
          </FlatCard>
          <FlatCard sx={{left: {sm: '15px', xs: '20px'}}}>
            <StyledImg src={wallet_dollar} alt="" sx={{width: {all: '95px', xs: '80px'}}} />
            <p>Aceptar varios métodos de pago</p>
          </FlatCard>
          <FlatCard sx={{left: {sm: '15px', xs: '20px'}}}>
            <StyledImg src={hand_wallet} alt="" sx={{width: {all: '70px', xs: '55px'}}} />
            <p>Ofrecer un flujo de pago sin fricciones.</p>
          </FlatCard>
          <FlatCard sx={{left: {sm: '-10px'}}}>
            <StyledImg src={team} alt="" sx={{width: {all: '95px', xs: '80px'}}} />
            <p>Equipar a todo tu personal con la app.</p>
          </FlatCard>
          <FlatCard sx={{left: {sm: '-10px'}}}>
            <StyledImg src={pos_card} alt="" sx={{width: {all: '53px', xs: '47px'}}} />
            <p>Utilizar un sistema TPV más ágil.</p>
          </FlatCard>
        </BackgroundContainer>
        <Content sx={{textAlign: 'center', marginTop: {all: '50px', sm: '80px'}}}>
          <MoreInfoButton />
        </Content>

        <Content sx={{marginTop: {all: '110px', md: '85px'}}}>
          <Section
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              width: {xs: '241px'}
            }}>
            <h2>
              ¿Por qué te <Bold>interesa</Bold>?
            </h2>
            <StyledImg
              src={corner}
              sx={{
                width: '104px',
                transform: 'rotate(190deg)',
                position: 'absolute',
                right: '-40px',
                top: '-55px',
                display: {xs: 'none'}
              }}
            />
            <p>
              Cualquier empresa que quiera aceptar pagos físicos desde su teléfono o tableta puede
              usar <Bold>MONEI Pay</Bold>.
            </p>
          </Section>
          <CardsContainer
            ref={sliderRef2}
            className="keen-slider"
            sx={{gap: {all: '48px', lg: '30px', md: '20px', xs: 'unset'}, overflow: 'visible'}}>
            <Card className="keen-slider__slide">
              <div className="imgWrapper">
                <StyledImg src={ecommerce} alt="e-commerce" sx={{width: '79px'}} />
              </div>
              <div>
                <h4>E-commerce y tiendas físicas</h4>
                <p>Acelera pagos y mejora la experiencia</p>
              </div>
            </Card>
            <Card className="keen-slider__slide">
              <div className="imgWrapper">
                <StyledImg src={restaurant} alt="restaurant" sx={{width: '78px'}} />
              </div>
              <div>
                <h4>Hostelería</h4>
                <p>Más flexibilidad que el TPV</p>
              </div>
            </Card>
            <Card className="keen-slider__slide">
              <div className="imgWrapper">
                <StyledImg src={freelancer_pos} alt="freelancer" sx={{width: '45px'}} />
              </div>
              <div>
                <h4>Autónomos</h4>
                <p>Es el TPV más flexible.</p>
              </div>
            </Card>
            <Card className="keen-slider__slide">
              <div className="imgWrapper">
                <StyledImg src={taxi_pos} alt="taxi" sx={{width: '74px'}} />
              </div>
              <div>
                <h4>Taxis</h4>
                <p>Sustituyendo al datáfono ahorrarás tiempo y dinero</p>
              </div>
            </Card>
            <Card className="keen-slider__slide">
              <div className="imgWrapper">
                <StyledImg src={hairdessers} alt="hairdessers" sx={{width: '62px'}} />
              </div>
              <div>
                <h4>Salones de belleza y peluquerías</h4>
                <p>Es una opción más cómoda, asequible y confiable.</p>
              </div>
            </Card>
          </CardsContainer>
          <Dots>
            {[0, 1, 2, 3, 4].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef2.current?.moveToIdx(idx);
                    setCurrentSlide2(idx);
                  }}
                  className={'dot' + (currentSlide2 === idx ? ' active' : '')}></button>
              );
            })}
          </Dots>
        </Content>

        <Content
          sx={{
            marginTop: {all: '110px', md: '85px'},
            display: 'flex',
            gap: {all: '148px', lg: '58px', md: '0 31px'},
            flexDirection: {all: 'row', md: 'column'}
          }}>
          <Section sx={{width: {all: '446px', xs: '241px'}}}>
            <h2>
              ¿Nos <Bold>necesitas?</Bold>
            </h2>
            <p>
              Si quieres más información o te ha quedado alguna duda, <Bold>¡contáctanos!</Bold>{' '}
              Estaremos encantados de ayudarte.
            </p>
          </Section>
          <StyledDiv sx={{display: 'flex', gap: {all: '54px', lg: '36px'}}}>
            <ContactSalesSquareButton>
              <StyledImg src={envelope} alt="mail" />
              <p>Escríbenos</p>
            </ContactSalesSquareButton>
            <SquareButtonLink variant={'dark'} href="tel:+34622488688">
              <StyledImg src={phone} alt="phone" />
              <p>
                Llámanos: <br />
                622 488 688
              </p>
            </SquareButtonLink>
          </StyledDiv>
        </Content>

        <Content sx={{marginTop: {all: '110px', md: '85px'}}}>
          <Section sx={{width: {xs: '241px'}}}>
            <h2>
              Marcas que ya <Bold>confían</Bold> en nosotros
            </h2>
            <StyledImg
              src={heart_baloon}
              sx={{
                width: '105px',
                margin: '0',
                position: 'absolute',
                right: '-130px',
                top: '-45px',
                display: {xs: 'none'}
              }}
            />
            <p>
              <Bold>Miles de marcas</Bold> confían en nosotros, desde empresas emergentes hasta
              pymes y grandes empresas.
            </p>
          </Section>
          <StyledDiv
            sx={{
              width: {all: '73%', md: '65%'},
              display: 'flex',
              flexWrap: 'wrap',
              gap: {all: '15px 70px', lg: '12px 50px', md: '12px 25px'}
            }}>
            {clientsList.map((client) => (
              <StyledClientLogo>
                <StyledImg {...client} />
              </StyledClientLogo>
            ))}
          </StyledDiv>
        </Content>
        <StyledImg
          src={moneiPayFooterBg}
          sx={{position: 'absolute', bottom: '0', right: '0', marginBottom: '0'}}
        />
        <StyledImg
          src={rocket}
          sx={{
            width: {all: '225px', lg: '185px', sm: '135px', xs: '104px'},
            position: 'absolute',
            right: {all: '308px', lg: '22%', sm: '20%', xs: '18%'},
            bottom: {all: '27px', sm: '20px', xs: '10px'},
            margin: '0'
          }}
        />
        <StyledImg
          src={moneiPayWhiteLogo}
          sx={{
            width: {all: '227px', lg: '185px', sm: '135px', xs: '78px'},
            position: 'absolute',
            right: {all: '83px', lg: '5%', sm: '2%'},
            bottom: {all: '53px', sm: '30px'},
            margin: '0'
          }}
        />
      </StyledDiv>
    </>
  );
};

export default SemEsp;
